import {getLocale} from "./translationUtil";

export const numberWithCommas = (x: any) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatCurrency = (value: number, fractionDigits: number = 2, currency: string = "USD"): string => {
    const validCurrencies = ["USD", "CAD", "GBP", "EUR"];
    if(validCurrencies.includes(currency)) {
        const formatter = Intl.NumberFormat("en-US", {
            style: "currency",
            currency,
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits
        });
        return formatter.format(value).replace("(", "-").replace(")", "");
    } else {
        return value.toString();
    }
};

export const formatCurrencyBasedOnLocale = (value: number, currencyName: string): string => {
    const locale = getLocale();
    const fractionDigits = 0;
    const formatter = Intl.NumberFormat(locale, {
        style: "currency",
        currency: currencyName,
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits
    });
    return formatter.format(value).replace("CA", "").replace("GB", "");
};

export const formatPercentBasedOnLocale = (value: number, fractionDigits: number = 2): string => {
    const locale = getLocale();
    const formatter = Intl.NumberFormat(locale, {
        style: 'unit',
        unit: 'percent',
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits
    });
    return formatter.format(value);
};

export const formatPercent = (value: number, fractionalDigits: number): string => {
    const prefix = value > 0
        ?  "+ "
        : value < 0
            ? "- "
            : "";
    return `${prefix}${Number.parseFloat((Math.abs(value) * 100).toFixed(fractionalDigits))}%`;
};

export const negativeToParenthesis = (value: string) => {
    return value.startsWith("-") ? value.replace("-", "(").concat(")") : value;
};
