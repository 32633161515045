import {Map} from "immutable";
import * as React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {
    allFlashReportsSummariesSelector,
    allManagersSelector,
    allMeetingNotesSelector,
    hasClientResearchEnabled,
    hasResearchAccess,
    isEditingPlanPortfolio,
    portfolioProductSummariesSelector,
    productUniverseSummariesSelector,
    selectProductSummariesBasedOnEditMode,
} from "../../../mainReducerMapSelectors";
import {FlashReportSummary} from "../../model/FlashReportSummary.model";
import {IStrategyUpdate} from "../../model/IStrategyUpdate.model";
import {ManagerResponse} from "../../model/manager/ManagerResponse.model";
import {MeetingNoteSummary} from "../../model/MeetingNoteSummary.model";
import {ProductSummary} from "../../model/product/ProductSummary";
import {byName} from "../../utils/listUtil";
import {ErrorComponent} from "../base/Error.component";
import {getSuccessData} from "../common/commonStates";
import {
    allFlashReportsSummariesRequestPair,
    allManagersRequestPair,
    allMeetingsNotesRequestPair,
    allProductsRequestPair,
} from "../common/RequesterPairs";
import {FlashReports} from "../flash-report/FlashReports.component";
import {MeetingNotes} from "../meeting-note/MeetingNotes.component";
import {FormattedMessage} from "react-intl";
import {multipleApiRequesterWrapper} from "../common/MultipleApiRequesterWrapper";
import {IApplicationRootState} from "../../../applicationState";
import {ManagerStrategies} from "./ManagerStrategies.component";

export interface IManagerComponentPropsFromParent {
    match: {
        params: IManagerPageUrlParams;
    };
}

export interface IManagerPageUrlParams {
    managerId: string;
}

export interface IManagerPagePropsFromStore {
    editingPlanPortfolio: boolean;
    productSummaries: Map<number, ProductSummary>;
    allManagers: Map<number, ManagerResponse>;
    productUniverseSummaries: Map<number, ProductSummary>;
    portfolioProductSummaries: Map<number, ProductSummary>;
    allFlashReportSummaries: Map<number, FlashReportSummary>;
    allMeetingNoteSummaries: Map<number, MeetingNoteSummary>;
    hasClientResearchEnabled: boolean;
    hasResearchAccess: boolean;
}

export type IManagerComponentProps =
    IManagerComponentPropsFromParent &
    IManagerPagePropsFromStore;

export class ManagerPageComponent extends React.Component<IManagerComponentProps> {

    public render() {
        const managerId = Number.parseInt(this.props.match.params.managerId, 10);
        const manager = this.props.allManagers.get(managerId)!;

        if ((!this.props.hasClientResearchEnabled && !this.props.hasResearchAccess)
            || !this.managerIsAvailable(manager)) {
            return <ErrorComponent/>;
        }

        // eslint-disable-next-line react/forbid-dom-props
        const link = <a id="manager-website" href={`${manager.website}`} {...{target: "_blank"}}>
            {manager.website}
        </a>;

        return (
            <div className="wealth-parent main-content manager-container new-common-styles">
                <div id="manager-info" className="manager-info">
                    <div className="manager-header">
                        <h1 id="manager-name">
                            {manager.name} - {/* space */}
                            <FormattedMessage id="manager.firm-summary" defaultMessage="Firm Summary"/>
                        </h1>
                        <div className="manager-website">
                            {link}
                        </div>
                    </div>
                    <ManagerStrategies {...{managerId, productSummaries: this.props.productSummaries}}/>
                    <FlashReports flashReportSummaries={this.getFlashReportSummaries()}/>
                    <MeetingNotes
                        meetingNotes={this.getMeetingNoteSummaries()}
                        managerId={manager.backstopId}/>
                </div>
            </div>
        );
    }

    private managerIsAvailable(manager: ManagerResponse | undefined): boolean {
        if (!manager) {
            return false;
        }
        return manager.inPortfolio || this.props.editingPlanPortfolio;
    }

    private getMeetingNoteSummaries() {
        return this.props.allMeetingNoteSummaries.valueSeq().toArray()
            .filter(this.isAttachedToManager);
    }

    private getFlashReportSummaries() {
        return this.props.allFlashReportSummaries.valueSeq().toArray()
            .filter(this.isAttachedToManager);
    }

    private isAttachedToManager = (strategyUpdate: IStrategyUpdate) => {
        return strategyUpdate.managerIds.includes(Number.parseInt(this.props.match.params.managerId, 10));
    };
}

export const mapStateToProps = (state: IApplicationRootState): IManagerPagePropsFromStore => {
    return {
        editingPlanPortfolio: isEditingPlanPortfolio(state),
        productSummaries: getSuccessData(selectProductSummariesBasedOnEditMode(state))!,
        allManagers: getSuccessData(allManagersSelector(state))!,
        productUniverseSummaries: getSuccessData(productUniverseSummariesSelector(state))!,
        portfolioProductSummaries: getSuccessData(portfolioProductSummariesSelector(state))!,
        allFlashReportSummaries: getSuccessData(allFlashReportsSummariesSelector(state))!,
        allMeetingNoteSummaries: getSuccessData(allMeetingNotesSelector(state))!,
        hasClientResearchEnabled: hasClientResearchEnabled(state),
        hasResearchAccess: hasResearchAccess(state),
    };
};
const connectedComponent = connect<IManagerPagePropsFromStore, null>(mapStateToProps, null)(ManagerPageComponent);

export default multipleApiRequesterWrapper(
    connectedComponent,
    [
        allManagersRequestPair,
        allMeetingsNotesRequestPair,
        allFlashReportsSummariesRequestPair,
        allProductsRequestPair(),
    ],
);
