import * as React from "react";
import {ReactNode} from "react";

export interface ISliderProps {
    header: string;
    content: ReactNode;
    display: boolean;
    hideSlider: () => void;
}

export const SliderComponent: React.FunctionComponent<ISliderProps> = (props: ISliderProps) => {
    const getMenuClass = () => {
        return ((props.display) ? "slider__tab-shown" : "slider__tab-hidden");
    };

    return <div className="slider__container" data-testid="slider__container">
                {props.display && <div className="menu__overlay" onClick={props.hideSlider} data-testid="menu__overlay"/>}
                <div id="slider__tab" className={getMenuClass()} data-testid="slider__tab">
                    <div className="slider__tab-arrow">
                        <a className="fas fa-arrow-right old-anchor" id="slider__tab-close" onClick={props.hideSlider}
                           data-testid="slider__close"/>
                        <span className="slider__tab-header">{props.header}</span>
                    </div>
                    <div className="slider__tab-text" data-testid="slider__tab-text">
                        {props.content}
                    </div>
                </div>
           </div>;
};