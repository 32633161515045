import * as React from "react";
import {useEffect, useState} from "react";
import {ErrorComponent} from "../base/Error.component";
import {connect} from "react-redux";
import {oddReportsEnabled} from "../../../mainReducerMapSelectors";
import {OperationalDueDiligenceApi} from "../../api/OperationalDueDiligenceApi";
import {OddReport} from "../../model/OddReport.model";
import {compareDates} from "../../utils/dateUtil";
import {SelectComponentReact} from "../common/ReactSelectComponent";
import {ISelectValue} from "../common/Select.component";
import {compareInsensitive} from "../../utils/listUtil";
import {OddReportsPaginator} from "./OddReportsPaginator";
import {getConfigPageSize} from "../../utils/commonUtil";
import {IApplicationRootState} from "../../../applicationState";

export interface IOddReportsPagePropsFromStore {
    oddReportsEnabled: boolean;
}

export type IOddReportsPageProps = IOddReportsPagePropsFromStore;

export const ALL_MANAGERS_SELECT_VALUE: ISelectValue = {name: "All Managers", id: -1};

export const ODD_REPORT_DESCRIPTION_HEADING = "Operational Due Diligence Overview";
export const ODD_REPORT_SHORT_DESCRIPTION = "The Operational Due Diligence reports listed below are an archive of " +
    "strategy specific reviews performed by Aon’s Operational Risk Solutions and Analytics team (ORSA)...";
export const ODD_REPORT_FULL_DESCRIPTION_1 = "The Operational Due Diligence reports listed below are an " +
    "archive of strategy specific reviews performed by Aon’s Operational Risk Solutions and Analytics team (ORSA), " +
    "previously Aon Investments’ Operational Due Diligence team. ORSA’s operational review process " +
    "considers a variety of the Manager’s policies, procedures, and capabilities across a range of " +
    "operations, middle and back office, and control functions. ORSA gathers information through a " +
    "combination of sources, which may include but are not limited to the following: Written responses " +
    "from the Manager to questionnaires or individual questions; review of documentation provided by " +
    "Manager; interviews with Manager personnel, whether in person or via teleconference; " +
    "interviews with Manager’s key service providers, whether in person or via teleconference; " +
    "and demonstrations of select operational processes.";
export const ODD_REPORT_FULL_DESCRIPTION_2 = "Each report is not intended to be a comprehensive review of the " +
    "entirety of Manager’s operational processes and is presented in summary format; it does not detail every topic " +
    "area considered as part of the assessment or consider every aspect of a Manager’s operations.  The reports " +
    "summarize Aon’s findings and there may be other interests, needs or issues that are of importance to investors " +
    "that are not addressed by the report.  Aon has not performed an audit of Manager and has relied upon " +
    "representations and statements made by Manager that are not verified for accuracy in every instance. " +
    "Further, Aon has not reviewed, or tested, in detail, any transactions or the internal controls of " +
    "Manager and accepts no responsibility or liability for any losses sustained by investors arising out of " +
    "such performance failures.  The information contained herein is believed to be reliable as of the date " +
    "of each report.";
export const ODD_REPORT_FULL_DESCRIPTION_HEADING = "Operational Risk Solutions and Analytics " +
    "(ORSA) Overview";
export const ODD_REPORT_FULL_DESCRIPTION_3 = "ORSA is specialist practice focused on identifying and " +
    "reducing business and operational risks within the operating environments of investment and financial market " +
    "participants. Our team is comprised of individuals with diverse backgrounds in a range of operational " +
    "disciplines, including operations, compliance, accounting, and counterparty risk. ORSA’s team members have " +
    "implemented operational due diligence projects within a range of operating environments globally, including " +
    "consultants, asset owners, and financial intermediaries. Collectively, the ORSA team has conducted operational " +
    "due diligence reviews of hundreds of investment managers globally and implemented dozens of operational " +
    "risk consulting projects for other financial markets participants. If you would like to learn more, " +
    "please reach out to your Aon consultant for further details regarding ORSA services.";

export const OddReportsPage: React.FunctionComponent<IOddReportsPageProps> = (props) => {
    const [oddReports, setOddReports] = useState<OddReport[]>([]);
    const [selectedManager, setSelectedManager] = useState<ISelectValue>(ALL_MANAGERS_SELECT_VALUE);
    const [fullDescription, setFullDescription] = useState<boolean>(false);

    const filteredReports = selectedManager.id === ALL_MANAGERS_SELECT_VALUE.id
        ? oddReports
        : oddReports.filter((report) => report.managers.includes(selectedManager.name));

    const renderOddReports = () => {
        return oddReports.length > 0
            ? <OddReportsPaginator reports={filteredReports} pageSize={getConfigPageSize()}/>
            : null;
    };

    const getManagers = (reports: OddReport[]): ISelectValue[] => {
        let managersArray: string[] = [];
        reports.map((report) => {managersArray = managersArray.concat(report.managers); });
        const unique = Array.from(new Set(managersArray)).sort(compareInsensitive);
        const values = unique.map((m: string, index: number) => {return {name: m, id: index}; });
        return [ALL_MANAGERS_SELECT_VALUE].concat(values);
    };

    const managers = oddReports.length > 0
        ? getManagers(oddReports)
            : [ALL_MANAGERS_SELECT_VALUE];

    const handleChange = (manager: ISelectValue) => {
        setSelectedManager(manager);
    };

    const renderShortDescriprtion = () => {
        return <div className="odd-reports__description-short" data-testid="odd-reports__description-short">
            <h3>{ODD_REPORT_DESCRIPTION_HEADING}</h3>
            <p>
                {ODD_REPORT_SHORT_DESCRIPTION}
                <span className="odd-reports__description-toggle clickable link-16"
                      data-testid="odd-reports__description-toggle"
                      onClick={() => setFullDescription(true)}>
                    More
                    <span className="fal fa-angle-down odd-reports__description-arrow"/>
                </span>
            </p>
        </div>;
    };

    const renderFullDescriprtion = () => {
        return <div className="odd-reports__description-full" data-testid="odd-reports__description-full">
            <h3>{ODD_REPORT_DESCRIPTION_HEADING}</h3>
            <p>{ODD_REPORT_FULL_DESCRIPTION_1}</p>
            <p>{ODD_REPORT_FULL_DESCRIPTION_2}</p>
            <h3>{ODD_REPORT_FULL_DESCRIPTION_HEADING}</h3>
            <p>{ODD_REPORT_FULL_DESCRIPTION_3}
                <span className="odd-reports__description-toggle clickable link-16"
                      data-testid="odd-reports__description-toggle" onClick={() => setFullDescription(false)}>
                    Less
                    <span className="fal fa-angle-up odd-reports__description-arrow"/>
                </span>
            </p>
        </div>;
    };

    const renderDescription = () => {
        return fullDescription ? renderFullDescriprtion() : renderShortDescriprtion();
    };

    const renderManagersFilter = () => {
        return <div className="odd-reports__filter-container-outer">
            <div className="odd-reports__filter-container">
                <div className="odd-reports__filter-label">
                    Filter:
                </div>
                    <div className="odd-reports__filter">
                        <div className="odd-reports__spacer">
                            <SelectComponentReact
                            className="odd-reports__managers-dropdown"
                            isMulti={false}
                            menuIsOpen={undefined}
                            options={managers}
                            value={selectedManager}
                            onChange={handleChange}
                            submitClicked={false}
                            getOptionLabel={(option: ISelectValue) => option.name}
                            getOptionValue={(option: ISelectValue) => option!.id!.toString(10)}
                            renderRequired={false}
                            stylesObject={{width: "500px"}}
                            />
                        </div>
                        <a className="odd-reports__clear-filter link-14 clickable"
                           data-testid="odd-reports__clear-filter"
                           onClick={() => setSelectedManager(ALL_MANAGERS_SELECT_VALUE)}>
                            Clear Filters
                        </a>
                    </div>
                </div>
        </div>;
    };

    useEffect(() => {
        OperationalDueDiligenceApi.requestAllOddReports()
            .then((response) => {
                setOddReports(response
                    .sort((a: OddReport, b: OddReport) => compareDates(a.effectiveDate, b.effectiveDate))
                );
            });

    }, []);
    return props.oddReportsEnabled
        ?   <div className="page-container new-common-styles odd-reports__content main-content"
                 data-testid="odd-reports-page">
                <h1>Operational Due Diligence ("ODD") Reports</h1>
                {renderDescription()}
                {renderManagersFilter()}
                {renderOddReports()}
            </div>
        :   <ErrorComponent />;
};

export const mapStateToProps = (state: IApplicationRootState): IOddReportsPagePropsFromStore => {
    return {
        oddReportsEnabled: oddReportsEnabled(state),
    };
};

export default connect(mapStateToProps)(OddReportsPage);

