import * as React from "react";
import {ISelectValue} from "../common/Select.component";
import {Label, Legend, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";
import {
    calculateColorAndOpacity,
    getTotalPlanNode,
    portfolioTreeFilteredByDate
} from "./portfolioUtil";
import {useEffect, useState} from "react";
import {IPlanTreeNodeFlatWithRating} from "../../model/Portfolio.model";
import {
    getMonthAndYearString,
    parseDate
} from "../../utils/dateUtil";
import {formatCurrency} from "../../utils/numberUtil";

export interface IAssetAllocationDonutChartProps {
    dataNodes: IPlanTreeNodeFlatWithRating[],
    selectedDate?: ISelectValue,
    key?: string
    titlePrefix: string
}

interface IPieChartData {
    name: string,
    value: number,
    fill: string,
    opacity: number
}

export const AssetAllocationDonutChart: React.FunctionComponent<IAssetAllocationDonutChartProps> = (props) => {
    const monthAndYear = getMonthAndYearString(parseDate(props.selectedDate?.id as string));
    const [nodes, setNodes] = useState<IPieChartData[]>([]);

    useEffect(() => {
        const date = (props.selectedDate?.id as string);
        setNodes(portfolioTreeFilteredByDate(props.dataNodes, date)
            .map((node, index) => {
                const colorAndOpacity = calculateColorAndOpacity(index);
                return {
                    name: `${node.name}, ${node.marketValuePctOfTotal.toFixed(1)}%`,
                    value: node.marketValue,
                    fill: colorAndOpacity.fill,
                    opacity: colorAndOpacity.opacity,
                };
            })
        );
    }, [props]);

    const renderMarketValue = () => {
        const totalNode = getTotalPlanNode(props.dataNodes, props.selectedDate?.id as string);
        return <div className="asset-allocation-donut-chart__market-value-container">
            {totalNode && <div className="asset-allocation-donut-chart__market-value-text">{props.titlePrefix} Market Value</div>}
            <div className="asset-allocation-donut-chart__market-value">{totalNode === undefined ? "" : formatCurrency(totalNode!.marketValue, 0)}</div>
        </div>;
    };

    const renderAssetAllocationDonutChart = () => {
        return <div className="asset-allocation-donut-chart__container"
                    data-testid="asset-allocation-donut-chart__container">
            {renderMarketValue()}
            <ResponsiveContainer width="100%" height={286}>
                <PieChartComponent nodes={nodes} monthAndYear={monthAndYear}/>
            </ResponsiveContainer>
        </div>;
    };

    return renderAssetAllocationDonutChart();
};

interface IPieChartComponentProps {
    nodes: IPieChartData[],
    monthAndYear: string
}

const PieChartComponent: React.FunctionComponent<IPieChartComponentProps> = (props) => {
    const customLegend = (props: any) => {
        const {payload} = props;

        return (
            <div className="donut-chart__legend-container">
                {payload.map((entry: any, index: number) => (
                    <div key={`item-${index}`}>
                        <div className="fa-solid fa-circle donut-chart__legend-dot" style={{
                            color: calculateColorAndOpacity(index).fill,
                            opacity: calculateColorAndOpacity(index).opacity,
                        }}/>
                        <div className="donut-chart__legend-text">{entry.value}</div>
                    </div>
                ))}
            </div>
        );
    };

    const customTooltip = (toolTipProps: any) => {
        if (toolTipProps.active && toolTipProps.payload && toolTipProps.payload.length) {
            return (
                <div className="custom-tooltip">
                    <div className="label">{toolTipProps.payload[0].payload.name.split(",")[0]}</div>
                    <div className="value">{formatCurrency(toolTipProps.payload[0].value, 0)}</div>
                </div>
            );
        }
        return null;
    };

    return <PieChart width={420} height={268}>
        <Pie
            dataKey="value"
            data={props.nodes}
            cx={120}
            cy={120}
            innerRadius={90}
            outerRadius={120}
            legendType={"circle"}
            inactiveShape={{style: {opacity: 0.3}}}
            activeShape={{style: {opacity: 1}}}

        >
            <Label width={30} position="center" className="quarter-label">{props.monthAndYear}</Label>
        </Pie>
        <Tooltip content={customTooltip} position={{x: -5, y: 205}}/>
        <Legend
            width={438}
            wrapperStyle={{
                top: 10,
                left: 235,
                lineHeight: "30px",
                textAlign: "left",
            }}
            content={customLegend}
        />
    </PieChart>;
};