import * as React from "react";
import {useState} from "react";
import {handleEnter} from "../../utils/commonUtil";
import AdminCreateClientPlan from "./AdminCreateClientPlan";
import {ClientsAndPlans} from "../../model/ClientsAndPlans.model";
import {getSuccessData} from "../common/commonStates";
import {connect} from "react-redux";
import {multipleApiRequesterWrapper} from "../common/MultipleApiRequesterWrapper";
import {adminClientsWithPlansPair} from "../common/RequesterPairs";
import {withVerifyAccess} from "../common/withVerifyAccess";
import {UserTypeEnum} from "../../model/UserInfo.model";
import {AdminAllClients} from "./AdminAllClients";
import {IApplicationRootState} from "../../../applicationState";


export enum AdminClientPlansTabs {
    ALL_CLIENTS = "All Clients",
    ADD_NEW = "Add New Client/Plan"
}

export interface IAdminClientPlansPageProps {
    adminClientInfoState: ClientsAndPlans;
}

export const AdminClientPlansPage: React.FunctionComponent<IAdminClientPlansPageProps> =
    (props: IAdminClientPlansPageProps) => {
    const[currentTab, setCurrentTab] = useState(AdminClientPlansTabs.ALL_CLIENTS);
    const getSelected = (tab: string) => currentTab
        ? tab.toUpperCase() === currentTab.toUpperCase() ? "selected" : ""
        : "";

    const renderContent = () => {
        return currentTab === AdminClientPlansTabs.ADD_NEW
        ? <AdminCreateClientPlan/>
            : <AdminAllClients adminClientsInfoState={props.adminClientInfoState}/>;
    };

    return <div className="new-common-styles admin-client-plan-page__container">
        <h1 className="admin-client-plan__header">Client Plan Administration</h1>
        <div className="tabs__container">
            <div className={`tab clickable ${getSelected(AdminClientPlansTabs.ALL_CLIENTS)}`}
                 tabIndex={0}
                 onKeyUp={handleEnter(() => setCurrentTab(AdminClientPlansTabs.ALL_CLIENTS))}
                 onClick={() => setCurrentTab(AdminClientPlansTabs.ALL_CLIENTS)}>
                <h6>{AdminClientPlansTabs.ALL_CLIENTS}</h6>
            </div>
            <div className={`tab clickable ${getSelected(AdminClientPlansTabs.ADD_NEW)}`}
                 tabIndex={0}
                 onKeyUp={handleEnter(() => setCurrentTab(AdminClientPlansTabs.ADD_NEW))}
                 onClick={() => setCurrentTab(AdminClientPlansTabs.ADD_NEW)}>
                <h6>{AdminClientPlansTabs.ADD_NEW}</h6>
            </div>
        </div>
        {renderContent()}
    </div>;
};

export const mapStateToProps = (state: IApplicationRootState): IAdminClientPlansPageProps => {
    return {
        adminClientInfoState: getSuccessData(state.adminState!.adminClientInfoState)!,
    };
};

const connected = connect<IAdminClientPlansPageProps>(mapStateToProps)(AdminClientPlansPage);
const withRequester = multipleApiRequesterWrapper(
    connected,
    [adminClientsWithPlansPair]
);

export default withVerifyAccess(withRequester, UserTypeEnum.ADMIN);