import {Map} from "immutable";
import * as React from "react";
import {connect} from "react-redux";
import {AnyAction, bindActionCreators, Dispatch} from "redux";
import {
    allManagersSelector,
    portfolioProductSummariesSelector,
    productUniverseSummariesSelector,
} from "../../../mainReducerMapSelectors";
import {ManagerResponse} from "../../model/manager/ManagerResponse.model";
import {MeetingNoteDetail} from "../../model/MeetingNoteDetail.model";
import {MeetingNoteSummary} from "../../model/MeetingNoteSummary.model";
import {ProductSummary} from "../../model/product/ProductSummary";
import {prominentButtonStyle} from "../common/buttonStyles";
import {RaisedButton} from "../common/RaisedButton";
import {formatShortDate} from "../../utils/dateUtil";
import {Brackets, BracketsBox} from "../base/Brackets.component";
import {getSuccessData} from "../common/commonStates";
import {allManagersRequestPair, allProductsRequestPair} from "../common/RequesterPairs";
import {ManagerNameList} from "../manager/ManagerNameList.component";
import {ProductNameList} from "../product/ProductNameList.component";
import meetingNoteActions from "./meetingNoteActions";
import {multipleApiRequesterWrapper} from "../common/MultipleApiRequesterWrapper";
import {FormattedMessage} from "react-intl";
import {IApplicationRootState} from "../../../applicationState";

interface IMeetingNotePropsFromParent {
    meetingNoteSummary: MeetingNoteSummary;
    meetingNoteDetail: MeetingNoteDetail;
    productId: number | undefined;
    managerId: number | undefined;
}

interface IMeetingNotePropsFromStore {
    allManagers: Map<number, ManagerResponse>;
    productUniverseSummaries: Map<number, ProductSummary>;
    portfolioProductSummaries: Map<number, ProductSummary>;
}

interface IMeetingNotePropsFromActions {
    actions?: typeof meetingNoteActions;
}

export type IMeetingNoteProps = IMeetingNotePropsFromParent
    & IMeetingNotePropsFromStore
    & IMeetingNotePropsFromActions;

interface IMeetingNoteState {
    collapseManagersAndProducts: boolean;
}

export class MeetingNoteComponent extends React.Component<IMeetingNoteProps, IMeetingNoteState> {

    constructor(props: IMeetingNoteProps) {
        super(props);

        this.state = {
            collapseManagersAndProducts: true,
        };
    }

    public render() {
        return (
            <div className="note-container no-bullets new-common-styles" data-testid="meeting-note-container">
                {this.renderTitle()}
                {this.renderLinks()}
                {this.renderContent()}
            </div>
        );
    }

    private renderTitle() {
        return <div className="strategy-update__header">
            <div className={"header-wrapper"}>
                <h1 id="meetingnote-title">
                    {this.props.meetingNoteSummary.title}
                </h1>
                {this.renderSubHeader()}
            </div>
            {this.getDownloadButton()}
        </div>;
    }

    private getDownloadButton = () => {
        return <RaisedButton
            className="pdf-download-button"
            style={prominentButtonStyle}
            primary={true}
            onClick={this.handleMeetingNoteClick}>
            <span className="pdf-download-button-body">
                <span className="pdf-download-button-text">
                     <FormattedMessage
                         id = "updates.download-pdf"
                         defaultMessage="Download PDF"
                     />
                </span>
                <span className="fal fa-download" />
            </span>
        </RaisedButton>;
    };

    private handleMeetingNoteClick = () => {
        if (this.props.actions) {
            this.props.actions.getMeetingNotePdf(this.props.meetingNoteSummary.backstopId,
                this.props.meetingNoteSummary.fileName);
        }
    };

    private renderSubHeader() {
        return <div id="meetingnote-subheader" className="note-row body-14">
            <span id="occurred-date">{formatShortDate(this.props.meetingNoteSummary.date)}</span>
            {this.props.meetingNoteDetail.location ? <span className="pipe-separator">|</span> : ""}
            <span id="location" data-testid="location">{this.props.meetingNoteDetail.location || ""}</span>
        </div>;
    }

    private renderLinks() {
        const {managerIds, productIds} = this.getManagerAndProductIds();

        return <div className="attachments">
            <ManagerNameList managerIds={managerIds} allManagers={this.props.allManagers}/>
            <ProductNameList productIds={productIds}
                             productUniverseSummaries={this.props.productUniverseSummaries}
                             portfolioProductSummaries={this.props.portfolioProductSummaries}/>
            {this.renderViewAll()}
        </div>;
    }

    private getManagerAndProductIds() {
        const allManagerIds = this.props.meetingNoteSummary.managerIds;
        const allProductIds = this.props.meetingNoteSummary.productIds;

        const [numManagerIds, numProductIds] =
            (this.hasLongList() && this.state.collapseManagersAndProducts)
                ? ((allManagerIds.length > 3) ? [3, 0] : [allManagerIds.length, 3 - allManagerIds.length])
                : [allManagerIds.length, allProductIds.length];

        return {managerIds: allManagerIds.slice(0, numManagerIds), productIds: allProductIds.slice(0, numProductIds)};
    }

    private hasLongList() {
        const {managerIds, productIds} = this.props.meetingNoteSummary;
        return managerIds.length + productIds.length > 3;
    }

    private renderViewAll() {
        if (!this.hasLongList()) {
            return;
        }
        const collapsed = this.state.collapseManagersAndProducts;
        const viewText = collapsed ? "View All" : "View Less";
        // TODO: Remove
        return <div className={`view-all link-14 clickable ${collapsed ? "list-collapsed" : "list-expanded"}`}
                    onClick={this.handleViewAllClick}>{viewText}</div>;
    }

    private handleViewAllClick = () => {
        this.setState({collapseManagersAndProducts: !this.state.collapseManagersAndProducts});
    };

    private renderContent() {
        return <Brackets>
            <BracketsBox>
                <div className="body-16">
                    <div id="meetingnote-content"
                         className="update-content"
                         dangerouslySetInnerHTML={this.createMarkup(this.props.meetingNoteDetail.description)}
                         data-testid="meetingnote-content"
                    />
                </div>
            </BracketsBox>
        </Brackets>;
    }

    private createMarkup(somehtml: string) {
        return {__html: somehtml};
    }
}

export const mapStateToProps = (state: IApplicationRootState) => {
    return {
        allManagers: getSuccessData(allManagersSelector(state))!,
        productUniverseSummaries: getSuccessData(productUniverseSummariesSelector(state))!,
        portfolioProductSummaries: getSuccessData(portfolioProductSummariesSelector(state))!,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IMeetingNotePropsFromActions => {
    return {
        actions: bindActionCreators(meetingNoteActions, dispatch),
    };
};

const connectedComponent =
    connect<IMeetingNotePropsFromStore,
        IMeetingNotePropsFromActions,
        IMeetingNotePropsFromParent>
    (mapStateToProps, mapDispatchToProps)(MeetingNoteComponent);

export default multipleApiRequesterWrapper(
    connectedComponent,
    [
        allManagersRequestPair,
        allProductsRequestPair(),
    ],
    undefined,
    null,
);
