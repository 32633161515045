import * as React from "react";
import {useEffect, useState} from "react";
import {formatCurrency} from "../../utils/numberUtil";
import {PortfolioApi} from "../../api/PortfolioApi";
import {getTotalPlanNode} from "./portfolioUtil";
import {LoadingSpinner} from "../icons/LoadingSpinner.component";
import {ErrorComponent} from "../base/Error.component";
import {IPlanTreeNodeFlat, IPlanTreeNodeFlatWithRating} from "../../model/Portfolio.model";
import {compareDates, formatMonthYearDate, parseDate} from "../../utils/dateUtil";
import {ISelectValue} from "../common/Select.component";
import {PlanTreeTable} from "./PlanTreeTable";
import {hasConsultantLevelAccess, hasPortfolioPageAccess} from "../../utils/sessionUtil";
import {connect, useDispatch, useSelector} from "react-redux";
import {
    hasClientPortfolioAccess,
    hasPortfolioAccess, portfolioProductSummariesSelector,
} from "../../../mainReducerMapSelectors";
import {NoAccessNotification} from "../base/header/NoAccessNotification";
import {setTreeDateAction} from "./PortfolioActions";
import {portfolioPlanTreeSelectedDate} from "./PortfolioMapSelectors";
import {AssetAllocationDonutChart} from "./AssetAllocationDonutChart";
import {NewSelectComponent} from "../common/NewSelect.component";
import {Map} from "immutable";
import {ProductSummary} from "../../model/product/ProductSummary";
import {IApplicationRootState} from "../../../applicationState";
import {getSuccessData} from "../common/commonStates";
import {multipleApiRequesterWrapper} from "../common/MultipleApiRequesterWrapper";
import {allProductsRequestPair} from "../common/RequesterPairs";

export interface IPortfolioPageProps {
    productSummaries: Map<number, ProductSummary>;
}

export enum PortfolioTabs {
    ASSET_ALLOCATION = "Asset Allocation",
    FINANCIAL_RECONCILIATION = "Financial Reconciliation",
    PERFORMANCE = "Performance",
}

export const PortfolioPage: React.FunctionComponent<IPortfolioPageProps> = (props) => {
    const portfolioAccess = useSelector(state => hasPortfolioAccess(state));
    const clientPortfolioAccess = useSelector(state => hasClientPortfolioAccess(state));
    const dateOptionFromStore = useSelector(state => portfolioPlanTreeSelectedDate(state));

    const [nodes, setNodes] = React.useState<IPlanTreeNodeFlatWithRating[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<boolean>(false);
    const [currentDateOption, setCurrentDateOption] = useState<ISelectValue | undefined>(undefined);
    const [dateOptions, setDateOptions] = useState<ISelectValue[]>([]);
    const [currentTab, setCurrentTab] = useState(PortfolioTabs.ASSET_ALLOCATION);

    const dispatch = useDispatch();

    const setDates = (nodes: IPlanTreeNodeFlat[]) => {
        const dates = Array.from(new Set(nodes
            .map((node) => node.date)))
            .sort((a, b) => compareDates(parseDate(a.toString()), parseDate(b.toString())));

        const dateOptions: ISelectValue[] = dates.map((date) => (
            {name: formatMonthYearDate(date), id: date})
        );
        setDateOptions(dateOptions);
        setCurrentDateOption(dateOptionFromStore ? dateOptionFromStore : dateOptions[0]);
    };

    const getRating = (backstopId: string): string => {
        const rating = props.productSummaries.get(Number(backstopId))?.currentRating;
        return rating || "";
    };

    const getNodes = (flatNodes: IPlanTreeNodeFlat[]) => {
        const ratingNodes = flatNodes.map((node: IPlanTreeNodeFlat): IPlanTreeNodeFlatWithRating => {
            return {...node, rating: node.backstopId ? getRating(node.backstopId) : ""};
        });
        setNodes(ratingNodes);
    };

    useEffect(() => {
        PortfolioApi.getCurrentPlanPortfolio()
            .then((response) => {
                setDates(response);
                getNodes(response);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                setError(true);
            });
    }, []);

    useEffect(() => {
        if (currentDateOption) dispatch(setTreeDateAction(currentDateOption));
    }, [currentDateOption]);

    const handleDateChange = (e: any) => {
        setCurrentDateOption(dateOptions.find((date) => date.id === e.target.value));
    };

    const renderDateSelection = () => {
        return <div className="portfolio__date-selection" data-testid="portfolio__date-selection">
            <div className="portfolio__label-as-of">As of:</div>
            <NewSelectComponent
                id={"portfolio__dates"}
                values={dateOptions}
                selected={currentDateOption?.id}
                width={200}
                handleChange={handleDateChange}
                classNameSuffix="filter-menu_portfolio-dates"
            />
        </div>;
    };

    const renderPlanTreeTable = () => {
        return currentDateOption &&
            <PlanTreeTable selectedDate={currentDateOption!} datedPlanNodes={nodes}/>;
    };

    const renderAssetAllocationDonutCharts = () => {
        const selectedDateIndex = dateOptions.findIndex(date => date.id === currentDateOption?.id);

        return dateOptions
            .filter((_, index) => index === selectedDateIndex || index === selectedDateIndex + 1)
            .map((date, index) => (
                <AssetAllocationDonutChart selectedDate={date} dataNodes={nodes} key={`donut-chart-${index}`}
                                           titlePrefix={index === 0 ? "Total" : "Previous Period"}/>
            ));
    };

    const renderTotalAssets = () => {
        const totalNode = getTotalPlanNode(nodes, currentDateOption?.id as string);
        return <div data-testid="portfolio__total-assets" className="portfolio__total-assets">
            {totalNode === undefined ? "" : formatCurrency(totalNode!.marketValue, 0)}
        </div>;
    };

    const renderClientAccessNotification = () => {
        return (hasConsultantLevelAccess() && !clientPortfolioAccess)
            ? <div className="portfolio-page__notification" data-testid={"portfolio-page__notification"}>
                <NoAccessNotification
                    message={"The Portfolio page is currently hidden from client users of this plan."}/>
            </div>
            : null;
    };

    const getSelected = (tab: PortfolioTabs) => tab === currentTab ? "selected" : "";

    const renderTabs = () => {
        return <div className="tabs__container">
            {renderTab(PortfolioTabs.ASSET_ALLOCATION)}
            {renderTab(PortfolioTabs.FINANCIAL_RECONCILIATION)}
            {renderTab(PortfolioTabs.PERFORMANCE)}
        </div>;
    };

    const renderTab = (tab: PortfolioTabs) => {
        return <div className={`tab clickable ${getSelected(tab)}`}
                    onClick={() => {
                        setCurrentTab(tab);
                    }}
                    data-testid="portfolio-page__tab">
            <h6>{tab}</h6>
        </div>;
    };

    const renderAssetAllocationContent = () => {
        return currentTab === PortfolioTabs.ASSET_ALLOCATION &&
            <div className="portfolio-page__asset-allocation-container"
                 data-testid={"portfolio-page__asset-allocation-container"}>
                {renderDateSelection()}
                <div className={"portfolio__asset-allocation-chart-container"}>
                    <div className="portfolio__asset-allocation-charts">{renderAssetAllocationDonutCharts()}</div>
                </div>
                <div className="portfolio__title-selection">
                    <div>
                        <div className="portfolio__label">
                            <h3>Total Assets</h3>
                            <p className="portfolio__sub-label-as-of" data-testid="portfolio__sub-label-as-of">
                                {`as of ${currentDateOption?.name}`}
                            </p>
                        </div>
                        {renderTotalAssets()}
                    </div>
                </div>
                {renderPlanTreeTable()}
            </div>;
    };

    const renderFinancialReconciliationContent = () => {
        return currentTab === PortfolioTabs.FINANCIAL_RECONCILIATION &&
            <div className="portfolio-page__financial-reconciliation-container"
                 data-testid="portfolio-page__financial-reconciliation-container">
                <h2>Financial Reconciliation</h2>
            </div>;
    };

    const renderPerformanceContent = () => {
        return currentTab === PortfolioTabs.PERFORMANCE &&
            <div className="portfolio-page__performance-container" data-testid="portfolio-page__performance-container">
                <h2>Performance</h2>
            </div>;
    };

    const renderContent = () =>
        <div className="main-content new-common-styles portfolio__page-container"
             data-testid="portfolio__page-container">
            {renderClientAccessNotification()}
            <h1>Portfolio Overview</h1>
            {renderTabs()}
            {renderAssetAllocationContent()}
            {renderFinancialReconciliationContent()}
            {renderPerformanceContent()}
        </div>;

    return hasPortfolioPageAccess(portfolioAccess, clientPortfolioAccess)
        ? loading
            ? <LoadingSpinner/>
            : error
                ? <ErrorComponent/>
                : renderContent()
        : <ErrorComponent/>;
};

export const mapStateToProps = (state: IApplicationRootState): IPortfolioPageProps => {
    return {
        productSummaries: getSuccessData(portfolioProductSummariesSelector(state))!,
    };
};

const connectedPortfolioPageComponent = connect<IPortfolioPageProps>(mapStateToProps)(PortfolioPage);

export default multipleApiRequesterWrapper(connectedPortfolioPageComponent, [allProductsRequestPair()]);