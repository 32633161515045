import * as React from "react";
import {useState} from "react";
import {hasConsultantLevelAccess} from "../../utils/sessionUtil";
import {ErrorComponent} from "../base/Error.component";
import {useSelector} from "react-redux";
import {hasPowerbiReportAdmin} from "../../../mainReducerMapSelectors";
import {PowerBiAdminAllReports} from "./PowerBiAdminAllReports";
import {PowerBiAdminAddReport} from "./PowerBiAdminAddReport";

export const PowerBiReportAdminPage: React.FunctionComponent = () => {
    const hasPowerBiAdminAccess = useSelector(state => hasPowerbiReportAdmin(state));
    const [viewAllReports, setViewAllReports] = useState<boolean>(false);


    const renderContent = () => {
       return viewAllReports ?
           <PowerBiAdminAllReports/>
           : <PowerBiAdminAddReport/>;
    };

    return hasConsultantLevelAccess() && hasPowerBiAdminAccess
        ? <div className="powerbi-admin__page" data-testid="powerbi-admin__page">
            <div className="powerbi-admin__header-container">
                <h1>PowerBI Report Administration</h1>
                <div className="powerbi-admin__view-link-container">
                    <a className="powerbi-admin__view-link old-anchor"
                       onClick={() => setViewAllReports(!viewAllReports)}>
                            {viewAllReports ? "Add Reports" : "View All Reports"}
                    </a>
                </div>
            </div>
            {renderContent()}
        </div>
        : <ErrorComponent />;
};

