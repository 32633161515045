import {MenuItem} from "@mui/material";
import {Menu} from "@mui/material";
import * as React from "react";
import {connect} from "react-redux";
import {Action, bindActionCreators, Dispatch} from "redux";
import {IPlanInfo} from "../../model/ClientsAndPlans.model";
import {getElementWidth} from "../../utils/browserUtil";
import RootActions from "../root/rootActions";
import HeaderActions from "./header/HeaderActions";
import CprPortfolioActions from "../home-page/CprPortfolioActions";
import {DialogComponent} from "../common/Dialog.component";
import {AON_TRUST_PLAN, orderPlans} from "../../utils/sessionUtil";

export interface IPlanListState {
    open: boolean;
    anchorEl?: HTMLElement;
    planNameWidth?: number;
    selectedPlan: IPlanInfo | undefined;
}

export interface IPlanListPropsFromParent {
    planList: IPlanInfo[];
    currentPlan: IPlanInfo;
}

export type ICPlanListProps = IPlanListPropsFromParent &
    IPlanListPropsFromActions;

export const ENTER_ATC_DIALOG_TITLE = "You are leaving the Aon Investment portal";
export const ENTER_ATC_DIALOG_DESCRIPTION = "You are leaving the Aon Investment Portal " +
    "and going to the Aon Collective Investment Trust portal.";
export const EXIT_ATC_DIALOG_TITLE = "You are leaving the Aon Collective Investment Trust portal";
export const EXIT_ATC_DIALOG_DESCRIPTION = "You are leaving the Aon Collective Investment Trust Portal " +
    "and going to the Aon Investment Portal.";

export class PlanList extends React.Component<ICPlanListProps, IPlanListState> {
    private static getPlanNameWidth() {
        return getElementWidth(".header__right") - 130;
    }

    public constructor(props: any) {
        super(props);

        this.state = {
            open: false,
            planNameWidth: 100,
            selectedPlan: undefined,
        };
    }

    public componentDidMount() {
        this.setState({planNameWidth: PlanList.getPlanNameWidth()});
        window.addEventListener("resize", () => {
            this.setState({planNameWidth: PlanList.getPlanNameWidth()});
        });
    }

    private navigateToPlan = (planInfo: IPlanInfo) => {
        this.props.rootActions!.resetPlanSettings();
        this.props.actions!.setCurrentPlan(planInfo);
        this.props.cprPortfolioActions!.clearFundingLevel();
    };

    private handleATCDialogAgree = () => {
        this.navigateToPlan(this.state.selectedPlan!);
    };

    private handleATCDialogCancel = () => {
        this.setState({...this.state, open: false, selectedPlan: undefined});
    };

    public render() {
        const planList: IPlanInfo[] = orderPlans(this.props.planList);

        if (planList.length === 1) {
            return <div id="plan-list">
                <a className="plan-list__plan-name old-anchor" style={{cursor: "default"}}>
                    <div className="plan-list__plan-name-container" style={{paddingRight: 16}}>
                        {this.props.currentPlan.name}
                    </div>
                </a>
            </div>;
        }

        const navigateFromAtcPlanToNonAtcPlan = () => {
            return this.props.currentPlan.name === AON_TRUST_PLAN && this.state.selectedPlan?.name !== AON_TRUST_PLAN;
        };

        const navigateToAtcPlanFromNonAtcPlan = () => {
            return this.state.selectedPlan?.name === AON_TRUST_PLAN && this.props.currentPlan.name !== AON_TRUST_PLAN;
        };

        const menuItems = planList.map((planInfo, index) =>
            <MenuItem
                className={`client-name-${index + 1} plan-${planInfo.name.replace(/ /g, "_")}`}
                key={planInfo.id}
                value={planInfo.id}
                style={{
                    fontFamily: 'Helvetica Now Text", sans-serif',
                    lineHeight: "48px",
                    fontSize: "16px",
                    whiteSpace: "nowrap",
                    fontWeight: "inherit",
                    paddingTop: 0,
                    paddingBottom: 0,
                }}
                onClick={() => {
                    if (this.props.currentPlan.name === AON_TRUST_PLAN || planInfo.name === AON_TRUST_PLAN) {
                        this.setState({...this.state, selectedPlan: planInfo, open: false});
                    } else {
                        this.setState({...this.state, selectedPlan: undefined});
                        this.navigateToPlan(planInfo);
                    }
                }}>
                {planInfo.name}
            </MenuItem>);

        const handleClick = (isClick: boolean) => (event: any) => {
            if (isClick || event.key === "Enter") {
                event.preventDefault();

                this.setState({
                    open: true,
                    anchorEl: event.currentTarget,
                });
            }
        };

        const renderATCDialog = () => {
            return navigateFromAtcPlanToNonAtcPlan() || navigateToAtcPlanFromNonAtcPlan()
                ? <DialogComponent
                    title={navigateToAtcPlanFromNonAtcPlan() ? ENTER_ATC_DIALOG_TITLE : EXIT_ATC_DIALOG_TITLE}
                    open={this.state.selectedPlan !== undefined}
                    noText="Cancel"
                    yesText="Agree"
                    onNo={this.handleATCDialogCancel}
                    onYes={this.handleATCDialogAgree}
                    description={navigateToAtcPlanFromNonAtcPlan()
                        ? ENTER_ATC_DIALOG_DESCRIPTION : EXIT_ATC_DIALOG_DESCRIPTION}
                />
                : null;
        };

        const getDropdownIconClass = () => {
            return this.props.currentPlan.name === AON_TRUST_PLAN
                ? "plan-list__icon fa-light fa-angle-down plan-list__icon-atc"
                : "plan-list__icon fa-light fa-angle-down";
        };

        return <div id="plan-list">
            <a className="plan-list__plan-name old-anchor clickable"
               aria-label="Plan Selection Dropdown"
               tabIndex={0}
               onClick={handleClick(true)}
               onKeyUp={handleClick(false)}
            >

                <div className="plan-list__plan-name-container" style={{maxWidth: this.state.planNameWidth}}>
                    {this.props.currentPlan.name}
                </div>
                <div className={getDropdownIconClass()} data-testid="plan-list__icon"/>
            </a>

            <Menu
                className="plan-list__menu"
                open={this.state.open}
                anchorEl={this.state.anchorEl}
                onClose={() => {
                    this.setState({open: false});
                }}
                style={{overflowX: "hidden", borderRadius: 0}}
                anchorOrigin={{vertical: "bottom", horizontal: "left"}}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
                {menuItems}
            </Menu>
            {renderATCDialog()}
        </div>;
    }
}

export interface IPlanListPropsFromActions {
    actions?: typeof HeaderActions;
    rootActions?: typeof RootActions;
    cprPortfolioActions?: typeof CprPortfolioActions
}

export const mapDispatchToProps = (dispatch: Dispatch<Action<void>>): IPlanListPropsFromActions => {
    return {
        actions: bindActionCreators(HeaderActions, dispatch),
        rootActions: bindActionCreators(RootActions, dispatch),
        cprPortfolioActions: bindActionCreators(CprPortfolioActions, dispatch)
    };
};

export default connect<any, IPlanListPropsFromActions,
    IPlanListPropsFromParent>(null, mapDispatchToProps)(PlanList);
