import * as React from "react";
import {useState} from "react";
import {SliderComponent} from "../common/Slider.component";

export type METHODOLOGY_MODE = "Portfolio" | "Product";
export interface IOddIqMethodology {
    mode: METHODOLOGY_MODE;
}
export const OddIqMethodologyComponent: React.FunctionComponent<IOddIqMethodology> = (props: IOddIqMethodology) => {
    const [displaySlider, setDisplaySlider] = useState(false);

    const getText = () => {
        if (props.mode === "Product") {
            return <div>
                <p>
                    This Operational Due Diligence IQ Summary Report (“ODD IQ Report”)
                    has been prepared by Aon after processing
                    responses to a proprietary due diligence questionnaire from underlying investment
                    managers (“Manager/s”).
                    Aon’s ODD IQ Summary Reports and program
                    have been developed to help identify and quantify operational risks that may exist within
                    investment managers or specific
                    investment products as a risk profiling exercise.
                </p>
               <p>
                   Aon has developed a range of operational due diligence factors that consider a
                   variety of the Manager’s policies, procedures,
                   and capabilities across its organizational and operational structure, including a range of
                   operations, support, and control functions.
                   The factors represented within the ODD IQ summary report include items within a proprietary
                   due diligence questionnaire that can be
                   readily characterized by preferred or non-preferred responses or otherwise compared to results
                   of other respondents. Non-preferred
                   responses are generally identified as exceptions to what Aon, in its sole discretion,
                   considers to be best or ideal practice.
                   Exceptions are further quantified by indicators of how common a given exception is
                   relative to other respondents to illustrate how the exception
                   links to market practice. Exceptions do not necessarily indicate a risk but are designed
                   to identify issues that an investor may want to explore
                   further for context or alignment with its own risk tolerance or underwriting standards.
               </p>
               <p>
                   ODD IQ summary reports are not intended to replace full, comprehensive ODD on an
                   investment manager or investment product.
                   They do not include our qualitative assessment or analysis of each risk item beyond what is
                   embedded in our processing models and they
                   do not test the veracity of any representation made by a respondent beyond certain control questions
                   embedded in the proprietary due
                   diligence questionnaire. The report is not intended to be a comprehensive review of the entirety of
                   the Manager’s operational processes
                   and is presented in summary format. The report summarizes Aon’s findings and there may be other
                   interests, needs or issues that are of
                   importance to investors that are not addressed by the ODD IQ summary report. Further,
                   Aon has not reviewed, or tested, in detail,
                   any transactions or the internal controls of the Manager and accepts no responsibility or
                   liability for any losses sustained by investors
                   arising out of such performance failures. The information contained herein is believed to
                   be reliable as of the date of the respondent’s due
                   diligence questionnaire submission, which may be prior to the date of the report.
               </p>
            </div>;
        } else {
            return <div>
                <p>
                    This Operational Due Diligence IQ Portfolio Report (“ODD IQ Portfolio Report”) has
                    been prepared by Aon after processing responses
                    to a proprietary due diligence questionnaire from underlying investment managers (“Manager/s”)
                    within the designated portfolio.
                    Aon’s ODD IQ Portfolio Reports and program have been developed to help identify and quantify
                    operational risks that may exist within
                    investment managers or specific investment products as a risk identification exercise.
               </p>
                <p>
                    Aon has developed a range of operational due diligence factors that consider a
                    variety of the Manager’s policies, procedures, and
                    capabilities across its organizational and operational structure, including a range of operations,
                    support, and control functions.
                    The factors represented within the ODD IQ Portfolio Report include items within the proprietary
                    due diligence questionnaire that can
                    be readily characterized by preferred or non-preferred responses or otherwise compared to results
                    of other respondents or aggregated
                    to present portfolio-level information that can help to inform an investor of operational risks
                    within its multi-manager portfolio.
                    Non-preferred responses are generally identified as exceptions to what Aon, in its sole discretion,
                    considers to be best or ideal
                    practice (“Exception/s”). Exceptions do not necessarily indicate a risk but are designed to
                    identify issues that an investor may want
                    to be aware of or explore further for context or alignment with its own risk tolerance or
                    underwriting standards. Information presented
                    herein can be supplemented and informed by information available within each manager-level report.
                </p>
                <p>
                    ODD IQ Portfolio Reports are not intended to replace full, comprehensive operational
                    due diligence on an investment manager or
                    investment product. They do not include Aon’s qualitative assessment or analysis
                    of each risk item beyond what is embedded in our
                    processing models and they do not test the veracity of any representation
                    made by a respondent beyond certain control questions
                    embedded in the proprietary due diligence questionnaire. The report is not
                    intended to be a comprehensive review of the entirety of
                    the underlying Manager’s operational processes and is presented in summary format.
                    The report summarizes Aon’s findings and there
                    may be other interests, needs or issues that are of importance to investors
                    that are not addressed by the ODD IQ Portfolio Report.
                    Further, Aon has not reviewed, or tested, in detail, any transactions or
                    the internal controls of any underlying manager and accepts
                    no responsibility or liability for any losses sustained by investors arising
                    out of such performance failures. The information contained
                    herein is believed to be reliable as of the date of each respondent’s
                    due diligence questionnaire submission, which may be prior to
                    the date of the report; however, we do not warrant its accuracy or completeness.
                </p>
            </div>;
        }
    };

    return <div>
        <div className="odd-iq__slider-link" onClick={() => setDisplaySlider(true)} data-testid="odd-iq-methodology">
            Methodology
        </div>
        <SliderComponent
            hideSlider={() => setDisplaySlider(false)}
            header="Methodology"
            content={getText()}
            display={displaySlider}/>
    </div>;

};
