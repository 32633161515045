import * as EmailValidator from "email-validator";
import * as React from "react";
import {connect} from "react-redux";
import {AnyAction, bindActionCreators, Dispatch} from "redux";
import {navigateTo} from "../../../navigateTo";
import {UserApi} from "../../api/UserApi";
import {IPlanInfo} from "../../model/ClientsAndPlans.model";
import {INewUser} from "../../model/NewUser.model";
import {getUserTypes, UserTypeEnum} from "../../model/UserInfo.model";
import {prominentButtonStyle, secondaryButtonStyle} from "../common/buttonStyles";
import {RaisedButton} from "../common/RaisedButton";
import {byName} from "../../utils/listUtil";
import {setHeaderNotification} from "../base/header/HeaderActions";
import {NotificationTypes} from "../base/header/HeaderReducer";
import {ISelectValue, SelectComponent} from "../common/Select.component";
import {TextFieldComponent} from "../common/TextField.component";
import {LoadingSpinner} from "../icons/LoadingSpinner.component";
import AdminAddPlanComponent from "./AdminAddPlan.component";
import {ApiError} from "../../model/ApiError.model";
import {MFA_SELECT_VALUES} from "./AdminUserEdit.component";
import {scrollToElement} from "../../utils/browserUtil";

export interface IAdminUserAddComponentState {
    firstName: string;
    lastName: string;
    email: string;
    userType: string | undefined;
    plans: IPlanInfo[];
    saveInProgress: boolean;
    submitClicked: boolean;
    isMfa: boolean;
}

export interface IAdminUserAddProps {
    actions: {
        setHeaderNotification: typeof setHeaderNotification;
    };
}

const initialAddUserState = {
    firstName: "",
    lastName: "",
    email: "",
    userType: undefined,
    plans: [],
    saveInProgress: false,
    submitClicked: false,
    isMfa: true
};

export class AdminUserAddComponent extends React.Component<IAdminUserAddProps, IAdminUserAddComponentState> {
    constructor(props: any) {
        super(props);
        this.state = {
            ...initialAddUserState,
        };
    }

    public render() {
        if (this.state.saveInProgress) {
            return <LoadingSpinner/>;
        }

        return (
            <div className="admin-user-add-page" data-testid="admin-user-add-page">
                <h1>Add User</h1>
                <div className="admin-user-add-page__edit-field">
                    {this.renderFirstNameField()}
                    {this.renderLastNameField()}
                    {this.renderEmailField()}
                    {this.renderUserTypeDropDown()}
                    {this.renderMfaGroupDropDown()}
                </div>
                {this.renderUserPlansSection()}
                <h2>Add Plan(s)</h2>
                <AdminAddPlanComponent
                    onAddPlan={this.handleSelectPlan}
                    renderRequired={true}
                    submitClicked={this.state.submitClicked}
                    plansSelected={this.state.plans.length > 0}
                />
                {this.renderButtons()}
            </div>

        );
    }

    private handleSelectPlan = (planInfo: IPlanInfo) => {
        if (this.state.plans.find((plan: IPlanInfo) => plan.id === planInfo.id)) {
            return;
        }

        const newPlanArray = this.state.plans.slice(0);
        newPlanArray.push(planInfo);
        newPlanArray.sort(byName);

        this.setState({plans: newPlanArray});
    };

    private handleFailure = (reason: string | undefined) => {
        this.setState({saveInProgress: false});
        this.props.actions.setHeaderNotification({
            message: `${reason}`,
            notificationType: NotificationTypes.FAILURE,
        }, 5000);
    };

    private handleSave = () => {

        if (this.state.firstName.length === 0
            || this.state.lastName.length === 0
            || this.state.email.length === 0
            || this.state.plans.length === 0
            || !this.state.userType) {
            this.setState({submitClicked: true});
            this.props.actions.setHeaderNotification({
                message: "Please complete all required fields before saving",
                notificationType: NotificationTypes.FAILURE,
            }, 5000);
            return;
        }

        if (!EmailValidator.validate(this.state.email)) {
            this.props.actions.setHeaderNotification({
                message: "invalid email format",
                notificationType: NotificationTypes.FAILURE,
            }, 5000);
            return;
        }

        const plans = this.state.plans.map ((plan) => plan.id);

        const user: INewUser = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            userType: this.state.userType.toUpperCase() as UserTypeEnum,
            isMfa: this.state.isMfa,
            planIds: plans,
        };

        UserApi.addUser(user)
            .then(() => {
                this.props.actions.setHeaderNotification({
                    message: `${user.firstName} ${user.lastName} Successfully saved`,
                    notificationType: NotificationTypes.SUCCESS,
                }, 5000);
                this.resetState(); },
                (rejected: {message: string}) => {
                    this.handleFailure(rejected.message);
                })
            .catch((reason: ApiError) => {
                this.handleFailure(reason.message);
        });
        scrollToElement(".main__header", 0);

        this.setState({saveInProgress: true});
    };

    private handleCancel = () => {
        navigateTo("/admin");
    };

    private resetState = () => {
        this.setState(
            {
                ...initialAddUserState,
            },
        );
    };

    private handleRemovePlan = (planInfo: IPlanInfo) => {
        const newPlansList = this.state.plans.filter((plan: IPlanInfo) => plan.id !== planInfo.id);
        this.setState({plans: newPlansList});
    };

    private renderUserPlansSection = () => {
        const renderUserPlan = (x: IPlanInfo) => {
            return <div className="admin-user-add-page__user-plan"
                        key={x.id}
                        data-testid="admin-user-add-page__user-plan">
                <div className="remove-icon fas fa-x" onClick={() => this.handleRemovePlan(x)}/>
                <div>{x.name}</div>
                <br/>
            </div>;
        };

        return <div className="admin-user-add-page__user-plan-section"
                    data-testid="admin-user-add-page__user-plan-section">
            <h2>Current Plans</h2>
            {this.state.plans.map(renderUserPlan)}
        </div>;
    };

    private renderFirstNameField = () => {
        return <TextFieldComponent
            name="first_name"
            className="admin-user-add-page__first-name-field"
            type="text"
            fullWidth={true}
            value={this.state.firstName}
            handleChange={(e: any) => { this.setState({firstName: e.target.value}); }}
            placeholder="First Name"
            renderRequired={true}
            submitClicked={this.state.submitClicked}
        />;
    };

    private renderLastNameField = () => {
        return <TextFieldComponent
            name="last_name"
            className="admin-user-add-page__last-name-field"
            type="text"
            fullWidth={true}
            value={this.state.lastName}
            handleChange={(e: any) => { this.setState({lastName: e.target.value}); }}
            placeholder="Last Name"
            renderRequired={true}
            submitClicked={this.state.submitClicked}
        />;
    };

    private renderEmailField = () => {
        return <TextFieldComponent
            name="email"
            className="admin-user-add-page__email-field"
            type="text"
            fullWidth={true}
            value={this.state.email}
            handleChange={(e: any) => { this.setState({email: e.target.value}); }}
            placeholder="Email"
            renderRequired={true}
            submitClicked={this.state.submitClicked}
        />;
    };

    private renderUserTypeDropDown = () => {
        const userTypes: ISelectValue [] = getUserTypes();

        return <div className="admin-user-add-page__user-type-mfa">
            <SelectComponent
                id={"admin-user-add-page__user-type"}
                values={userTypes}
                selected={this.state.userType}
                width={230}
                handleChange={(e: any) => {
                    this.setState({userType: e.target.value});
                }}
                placeholder={"User Type"}
                renderRequired={true}
                submitClicked={this.state.submitClicked}
            />
        </div>;
    };

    private renderMfaGroupDropDown() {
        return <div className="admin-user-add-page__user-type-mfa">
            <SelectComponent
                id={"admin-user-add-page__user-mfa"}
                values={MFA_SELECT_VALUES}
                selected={Number(this.state.isMfa)}
                width={150}
                submitClicked={false}
                handleChange={(e: any) => {
                    this.setState({isMfa: Boolean(e.target.value)});
                }}
                placeholder={"Mfa Type"}
                renderRequired={false}
            />
        </div>;
    }

    private renderButtons() {
        return <div>
            <div className="buttons-spacer-top"/>
            <div className="save-and-cancel-buttons">
                <div className="buttons-spacer-left"/>
                <RaisedButton className="admin-user-add-page__cancel-button"
                              style={secondaryButtonStyle}
                              primary={false}
                              onClick={() => this.handleCancel()}>
                    Cancel
                </RaisedButton>
                <div className="buttons-spacer-between"/>
                <RaisedButton className="admin-user-add-page__save-button"
                              style={prominentButtonStyle}
                              primary={true}
                              onClick={() => this.handleSave()}>
                    Save
                </RaisedButton>
            </div>
            <div className="buttons-spacer-bottom"/>
        </div>;
    }
}

export function mapDispatchToProps(dispatch: Dispatch<AnyAction>): IAdminUserAddProps {
    return {
        actions: bindActionCreators({
            setHeaderNotification,
        }, dispatch),
    };
}

export default connect<{}, IAdminUserAddProps>(null, mapDispatchToProps)(AdminUserAddComponent);
