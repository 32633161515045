import * as React from "react";
import {useEffect, useState} from "react";
import {IOddQuestionSummary, IOddSectionsQuestions, OddLevelId} from "../../../api/OddIqApi";
import {getSectionsQuestionsByLevel} from "../OddIqUtils";
import {OddResultsByRiskCategoryChart} from "./OddResultsByRiskCategoryChart";
import {OddTotalResultsByRiskCategoryChart} from "./OddTotalResultsByRiskCategoryChart";

export interface IResultsByRiskCategoryProps {
    oddQuestionSummaries: IOddQuestionSummary[]
    oddSectionsQuestions: IOddSectionsQuestions;
    currentQuestionId?: number;
}

const managerLevelDescription = <div>
    <p>
        Manager level risk factors are items that we generally consider firm-level business operating policies and
        procedures which typically apply to all strategies that a firm manages.
    </p>
    <p>
        The summary information below considers exceptions within these areas. It is designed to help identify which
        Managers within the defined portfolio demonstrate either a higher number of total exceptions or exceptions
        that are unusual relative to other managers within the portfolio.
    </p>
</div>;
const strategyLevelDescription = <div>
    <p>
        Strategy level risk factors are items that we generally consider investment strategy (or fund)-level
        operating policies and procedures which may apply only to the strategy in scope.
    </p>
    <p>
        The summary information below considers exceptions within these areas. It is designed to help identify which
        strategies within the defined portfolio demonstrate either a higher number of total exceptions or exceptions
        that are unusual relative to other strategies within the portfolio.
    </p>
</div>;

export const ResultsByRiskCategory: React.FunctionComponent<IResultsByRiskCategoryProps> = (props) => {
    const [selectedRiskLevelIndex, setSelectedRiskLevelIndex] = useState<number>(0);

    const managerLevelSectionsQuestions = getSectionsQuestionsByLevel
    (props.oddSectionsQuestions, OddLevelId.Manager);
    const strategyLevelSectionsQuestions = getSectionsQuestionsByLevel
    (props.oddSectionsQuestions, OddLevelId.Strategy);

    useEffect(() => {
        if (props.currentQuestionId) {
            const newLevelIndex = managerLevelSectionsQuestions.questions
                .find((q) => q.id === props.currentQuestionId)
                ? 0
                : 1;
            setSelectedRiskLevelIndex(newLevelIndex);
        }
    }, [props.currentQuestionId]);

    const getSelectedClass = (currentTabIndex: number) => {
        return currentTabIndex === selectedRiskLevelIndex ? " selected" : "";
    };

    const renderResultsByRisk = () => {

        const managerQuestionSummaries = props.oddQuestionSummaries.filter(
            (summary) => managerLevelSectionsQuestions.questions
                .find((question) => question.id === summary.questionId)
        );

        const strategyQuestionSummaries = props.oddQuestionSummaries.filter(
            (summary) => strategyLevelSectionsQuestions.questions
                .find((question) => question.id === summary.questionId)
        );

        return  <div key={selectedRiskLevelIndex} className="tabs__displayed-component">
                    <div className="odd-page__results-by-risk-category">
                        <div className="odd-page__level-description">
                            {selectedRiskLevelIndex === 0 ? managerLevelDescription : strategyLevelDescription}
                        </div>
                        <OddTotalResultsByRiskCategoryChart oddQuestionSummaries={selectedRiskLevelIndex === 0
                                                                                    ? managerQuestionSummaries
                                                                                    : strategyQuestionSummaries}
                         />
                    </div>
                    <div className="odd-page__breakdown-chart-container">
                    <OddResultsByRiskCategoryChart
                            oddQuestionSummaries = {selectedRiskLevelIndex === 0
                                                    ? managerQuestionSummaries
                                                    : strategyQuestionSummaries}
                           oddSectionsQuestions = {selectedRiskLevelIndex === 0
                                                   ? managerLevelSectionsQuestions
                                                    : strategyLevelSectionsQuestions}
                           currentQuestionId = {props.currentQuestionId}

                    />
                    </div>
                </div>;
    };

    return <div id="odd-horizontal-bar__component">
        <div id="odd-page__bar-chart-title-and-about-container">
            <div id="bar-chart__title">
                <h3 className="odd-page__bar-chart-header">Results by Risk Category</h3>
            </div>
        </div>
        <div className="common__tabs-container">
            <div className="tabs__container">
                <div className={`tab clickable ${getSelectedClass(0)}`}
                     onClick={() => setSelectedRiskLevelIndex(0)}
                     key={0}>
                    <h6>Manager Level Categories</h6>
                </div>
                <div className={`tab clickable ${getSelectedClass(1)}`}
                     onClick={() => setSelectedRiskLevelIndex(1)}
                     key={1}>
                    <h6>Strategy Level Categories</h6>
                </div>
            </div>
            {renderResultsByRisk()}
        </div>
    </div>;
};