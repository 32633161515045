import * as React from "react";
import {getTranslation} from "../../utils/translationUtil";

export const BUY_DESCRIPTION = "We recommend clients invest with or maintain their existing allocation to our Buy rated high conviction products.";
export const BUY_CLOSED_DESCRIPTION = "We recommend clients invest with or maintain their existing allocation to our Buy rated high conviction products, however it is closed to new investors.";
export const QUALIFIED_DESCRIPTION = "A number of criteria have been met and we consider the investment manager to be qualified to manage client assets.";
export const SELL_DESCRIPTION = "We recommend termination of client investments in this product.";
export const IN_REVIEW_DESCRIPTION = "The rating is under review as we evaluate factors that may cause us to change he current rating.";
export const NOT_RECOMMENDED_DESCRIPTION = "A quantitative assessment of this strategy indicates it does not meet our desired criteria for investment. This strategy is not recommended.";
export const PASS_DESCRIPTION = "Aon noted no material operational concerns.";
export const A1_PASS_DESCRIPTION = "Aon noted no material operational concerns and considers the firm’s operations largely align with a well-controlled operating environment.";
export const A2_PASS_DESCRIPTION = "The firm’s operations largely align with a well-controlled operating environment, with limited exceptions. Managers may be rated within this category due to resource limitations or where isolated areas do not align with best practice.";
export const CONDITIONAL_PASS_DESCRIPTION = "Aon noted specific operational concerns that the firm has agreed to address in a reasonable timeframe. Upon resolution, Aon will review the firm’s rating.";
export const FAIL_DESCRIPTION = "Aon noted material operational concerns that introduce the potential for economic or reputational exposure.";

export const RatingDefinitions: React.FunctionComponent = () => {
    const renderRatings = (header: string, description: string) => {
        return <div className="rating-definition__rating-container">
            <div className="rating-definition__rating-header">{header}</div>
            <div className="rating-definition__rating-description">{description}</div>
        </div>;
    };

    return <div data-testid="rating-definition__container">
        <div className="rating-definition__overall-rating-section">
            {getTranslation("research-page.aon-overall-rating", "Aon Overall Rating")}
        </div>
        {renderRatings(getTranslation("research-filter-rating.Buy", "Buy"), getTranslation("research-page.rating-definition-buy-description", BUY_DESCRIPTION))}
        {renderRatings(getTranslation("research-product-rating.Buy (Closed)", "Buy (Closed)"), getTranslation("research-page.rating-definition-buy-closed-description", BUY_CLOSED_DESCRIPTION))}
        {renderRatings(getTranslation("research-product-rating.Qualified", "Qualified"), getTranslation("research-page.rating-definition-qualified-description", QUALIFIED_DESCRIPTION))}
        {renderRatings(getTranslation("research-product-rating.Sell", "Sell"), getTranslation("research-page.rating-definition-sell-description", SELL_DESCRIPTION))}
        {renderRatings(getTranslation("research-product-rating.In Review", "In Review"), getTranslation("research-page.rating-definition-in-review-description", IN_REVIEW_DESCRIPTION))}
        {renderRatings(getTranslation("research-product-rating.Not Recommended", "Not Recommended"), getTranslation("research-page.rating-definition-not-recommended-description", NOT_RECOMMENDED_DESCRIPTION))}
        <div className="rating-definition__odd-rating-section">{getTranslation("research-page.odd-rating", "Aon ODD Ratings")}</div>
        {renderRatings(getTranslation("research-product-rating.Pass", "Pass"), getTranslation("research-page.rating-definition-pass-description", PASS_DESCRIPTION))}
        {renderRatings(getTranslation("research-product-rating.A1 Pass", "A1 Pass"), getTranslation("research-page.rating-definition-a1-pass-description", A1_PASS_DESCRIPTION))}
        {renderRatings(getTranslation("research-product-rating.A2 Pass", "A2 Pass"), getTranslation("research-page.rating-definition-a2-pass-description", A2_PASS_DESCRIPTION))}
        {renderRatings(getTranslation("research-product-rating.Conditional Pass", "Conditional Pass"), getTranslation("research-page.rating-definition-conditional-pass-description", CONDITIONAL_PASS_DESCRIPTION))}
        {renderRatings(getTranslation("research-product-rating.Fail", "Fail"), getTranslation("research-page.rating-definition-fail-description", FAIL_DESCRIPTION))}
    </div>;
};


